<template>
  <div>
    <div id="catalogHash" class="page-catalog">
      <catalog-slider style="min-height: 425px" />
      <div class="container">
        <div class="page-catalog__header">
          <div class="page-catalog__nav">
            <locale-router-link to="" class="crumbs-link main-page-link">
              {{ $t("home") }}</locale-router-link
            >
            <a class="crumbs-link page-catalog-link">
              {{ $t("discountCatalog") }}</a
            >
          </div>
          <div class="page-catalog__inner">
            <h2 class="title">{{ $t("discountCatalog") }}</h2>
            <span
              class="page-catalog__pdf"
              v-if="pdfProducts.length"
              @click="getPdf"
            >
              {{ $t("downloadPdf") }}
            </span>
          </div>
          <button
            class="page-catalog-filter"
            :class="{ 'is-active': isShowFilter }"
            @click="showFilter"
          >
            {{ $t("filter") }}
          </button>
        </div>
        <div class="page-catalog__content">
          <discount-filters
            @load-products-by-filter="loadProductsByFilter"
            @close-filter="isShowFilter = false"
            :isShowFilter="isShowFilter"
          />

          <div class="page-catalog__products">
            <div class="page-catalog__sorting">
              <div class="page-catalog__found">
                <span>{{ $t("found") }}</span>
                <span
                  v-if="countProducts || countProducts === 0"
                  class="number-found-product"
                  >{{ countProducts }}</span
                >
                <span> {{ $t("goods") }}</span>
              </div>

              <div
                class="page-catalog__sorting-btn"
                @click="isShowSort = !isShowSort"
              >
                {{ $t("sorting") }}
              </div>
              <discount-sort
                v-if="isShowSort"
                @sort-btn="isShowSort = false"
                @sort-start="getFiltredProducts"
              />
            </div>
            <div class="page-catalog__items">
              <template v-if="products">
                <product-card
                  v-for="product in products"
                  :key="product.id"
                  :item="product"
                  :catalog="true"
                />
              </template>

              <button
                v-if="!isLastPage"
                class="catalog-filter-more"
                @click="nextPage"
              >
                {{ $t("seeAll") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pdf-catalog :pdfProducts="pdfProducts" :allProducts="allProducts" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/catalog";
import { getAllProducts } from "@/api/products";
export default {
  name: "Catalog",
  components: {
    ProductCard: () => import("@/components/products/ProductCard.vue"),
    DiscountFilters: () => import("@/components/discount/DiscountFilters.vue"),
    DiscountSort: () => import("@/components/discount/DiscountSort.vue"),
    CatalogSlider: () =>
      import("@/components/sliders/catalog/CatalogSlider.vue"),
    PdfCatalog: () => import("@/components/pdf/PdfCatalog.vue"),
  },
  data() {
    return {
      pagination: {
        page: 1,
        pageSize: 15,
      },
      isShowFilter: false,
      isLastPage: false,
      isShowSort: false,
    };
  },
  computed: {
    ...mapState("catalogModule", {
      products: "data",
      isLoading: "isLoading",
    }),
    ...mapState("cityModule", {
      selectedCity: "selectedCity",
    }),
    ...mapState("catalogModule", ["pdfProducts", "allProducts"]),

    ...mapGetters("catalogModule", ["isFilterEmpty"]),
    countProducts() {
      return this.products?.length;
    },
  },
  watch: {
    isFilterEmpty: {
      handler(newVal) {
        if (!newVal) {
          this.getFiltredProducts();
        }
      },
    },
    "$route.query.city_id": {
      handler() {
        if (this.$isMobile()) {
          this.getFiltredProducts({
            pagination: this.pagination,
            isPaginate: true,
          });
        } else {
          this.getFiltredProducts();
        }
      },
    },
  },

  created() {
    if (!this.$route.query.filters) {
      if (this.$isMobile()) {
        this.getFiltredProducts({
          pagination: this.pagination,
          isPaginate: true,
          cityId: this.$route.query.city_id,
        });
      } else {
        this.getFiltredProducts({ cityId: this.$route.query.city_id });
      }
    }
    getAllProducts(1, 500, this.selectedCity.id).then((response) => {
      this.getAllProducts(response.data);
    });
  },
  beforeDestroy() {
    this.clearFilters();
    const { query } = this.$route;
    const qs = { city_id: query.city_id };
    this.$router.replace({
      query: qs,
    });
  },
  methods: {
    ...mapActions("catalogModule", {
      getFiltredProducts: actionTypes.getFiltredProducts,
      clearFilters: actionTypes.clearFilters,
      getAllProducts: actionTypes.getAllProducts,
    }),
    getPdf() {
      try {
        // Print for Safari browser
        document.execCommand("print", false, null);
      } catch {
        window.print();
      }
    },
    showFilter() {
      this.isShowFilter = !this.isShowFilter;
    },
    loadProductsByFilter() {
      this.getFiltredProducts({ cityId: this.$route.query.city_id });
    },
    nextPage() {
      this.pagination.page++;
      this.getFiltredProducts({
        pagination: this.pagination,
        isPaginate: true,
      }).then(({ data, meta }) => {
        if (
          meta.pagination?.pageCount === this.pagination.page ||
          data?.length === 0
        ) {
          this.isLastPage = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.file {
  display: none;
}

@media print {
  body {
    margin: 0px;
  }
  .file {
    display: block;
  }
  .page-catalog {
    display: none;
  }
  .header,
  .header-nav {
    display: none;
  }
  .scroll__top.is-show {
    display: none;
  }
}
</style>
